import {
  observableFetchClient,
  createAuthorizationHeaders,
} from '@vp/common-api-utils';
import { getAuthHeaderAndShopperId } from '@vp/common-api-utils/authHelpers';

const VISTACART_URL = 'https://vistacart.orders.vpsvc.com';

const buildVistacartApiParams = () => ({
  requestor: 'cart-page-fragment',
});

export function getShopperCart(
  tenant: string,
  shopperId: string,
  shouldLoadOffline?: boolean
) {
  const { authHeader } = getAuthHeaderAndShopperId();
  let url = `${VISTACART_URL}/tenants/${tenant}/carts/${shopperId}`;
  if (shouldLoadOffline) {
    url += `/offline`;
  }
  return observableFetchClient({
    url,
    params: buildVistacartApiParams(),
    method: 'get',
    headers: createAuthorizationHeaders(authHeader),
    timeout: 10000,
    maxAttempts: 2,
  }).then((res: any) => res.json());
}
