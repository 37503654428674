import React from 'react';
import { Card, FlexBox, Typography, Spinner } from '@vp/swan';
import { useTranslations } from '@vp/om-translations';
import useSiteData from '../hooks/useSiteData';
import useIdentityContext from '../hooks/useIdentityContext';
import { CartApplication } from './CartApplication';

export const CartSession = () => {
  const { auth, isIdentityInitialized, identity } = useIdentityContext();
  const { vistacartTenant } = useSiteData();
  const [cartMessages] = useTranslations(['cartMessages']);

  const { isShopper, shopperId } = identity;

  // Session Identity unknown, display Preloader as auth does its thing.
  if (!isIdentityInitialized)
    return (
      <FlexBox justifyContent="center">
        <Spinner
          size="super"
          accessibleText={cartMessages.loading}
          aria-busy="true"
          aria-live="polite"
          role="status"
          my={9}
        />
      </FlexBox>
    );

  // something for internal users

  // if shopper
  if (isShopper) {
    return <CartApplication tenant={vistacartTenant} shopperId={shopperId!} />;
  }

  // if anonymous user

  return (
    <FlexBox>
      <Card style={{ width: '100%' }}>
        <Typography textAllCaps fontWeight="bold" mb={1} mr={1}>
          Hello World from cart-page fragment!{' '}
          {`IsSignedIn: ${auth?.isSignedIn()}`}
        </Typography>
      </Card>
    </FlexBox>
  );
};
