import { type IdentityProviderProps } from '@vp/ubik-context';

type VPAuthConfig = IdentityProviderProps['auth'];

export const createAuthConfig = (culture: string): VPAuthConfig => ({
  culture,
  developmentMode: true,
  options: {
    requireSession: true,
    customText: 'Sign in to see your account',
  },
});
