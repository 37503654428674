import React from 'react';
import { SWAN_STYLE_KEY_MAP } from '@vp/swan';
import {
  useSwanStyleKeys,
  useUserContext,
  IdentityProvider,
} from '@vp/ubik-context';
import { LocalizationProvider } from '@vp/om-translations';
import { createAuthConfig } from './utils/auth';
import { CartSession } from './components/CartSession';
import { SiteProvider } from './components/contexts/SiteContext';
import translationsResources from './assets/i18n';

export type RawSiteData = {
  siteName: string;
  country: string;
  siteBaseUrl: string;
  vistacartTenantName: string;
  uiLocalization: {
    locale: string;
    language: string;
  };
  tenantEnvironment: 'PRODUCTION' | 'STAGING';
  nav: {
    base: string;
    cart: string;
    checkout: string;
    delivery: string;
    myProjects: string;
    orderDetails: string;
    orderHistory: string;
    privacyPolicy: string;
    termsAndConditions: string;
  };
  pricingDisplay: {
    taxDisplayMode: string; // could possibly enumerate here
  };
  auth: {
    allowAnonymous: boolean;
    site: string;
  };
  noCouponEntry: boolean;
  uxMods: [
    {
      modRef: {
        family: string;
        modId: string;
      };
      // all mods in the site configuration will be page
      scope: 'page';
    },
  ];
};

export type ServerSideProps = { siteData: RawSiteData };

export type Props = ServerSideProps;

function App(props: Props) {
  const context = useUserContext();
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.spinner,
    // Required by PPAG UX Mods
    SWAN_STYLE_KEY_MAP.selectionSet,
    // Required by Product Recommendation Mod
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.table,
    SWAN_STYLE_KEY_MAP.listbox,
    SWAN_STYLE_KEY_MAP.progressBar,
    SWAN_STYLE_KEY_MAP.pricing,
    SWAN_STYLE_KEY_MAP.banner,
  ]);

  const { siteData } = props;

  return (
    <IdentityProvider auth={createAuthConfig(context.locale)}>
      <SiteProvider value={{ ...siteData }}>
        <LocalizationProvider
          translationResources={translationsResources}
          locale={context.locale}
          logLevel="ERROR"
        >
          <CartSession />
        </LocalizationProvider>
      </SiteProvider>
    </IdentityProvider>
  );
}

export default App;
