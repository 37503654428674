import { clientMount } from '@vp/ubik-fragment-react';
import type { MountWithRootOptions } from '@vp/ubik-fragment-types';
import React from 'react';
import { Fragment as Root, Props as AppProps } from '../fragment';

export const mount: MountWithRootOptions<AppProps> = async (
  rootElement,
  renderProps,
  rootOptions
) =>
  clientMount(
    <Root siteData={renderProps.siteData} />,
    rootElement,
    rootOptions
  );
