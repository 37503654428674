import React from 'react';
/**
 * Without adding the complexity of Redux we can use this Context to pass
 * site-wide properties like locale/langCodes and
 * other useful siteConfig-based values into components that are nested deep,
 * avoiding passing the values down through prop hell.
 */
const SiteContext = React.createContext<any>(undefined);

export const SiteProvider = SiteContext.Provider;
export const SiteConsumer = SiteContext.Consumer;
export default SiteContext;
