import enGB from './en-gb.json';
import enIN from './en-in.json';
import ptPT from './pt-pt.json';
import esES from './es-es.json';
import enUS from './en-us.json';
import enAU from './en-au.json';
import itIT from './it-it.json';
import frFR from './fr-fr.json';
import deDE from './de-de.json';
import daDK from './da-dk.json';
import svSE from './sv-se.json';
import nbNO from './nb-no.json';
import fiFI from './fi-fi.json';
import nlNL from './nl-nl.json';
import frCA from './fr-ca.json';
import esUS from './es-us.json';

export default {
  'da-dk': daDK,
  'de-at': deDE,
  'de-ch': deDE,
  'de-de': deDE,
  'en-ca': enUS,
  'en-gb': enGB,
  'en-au': enAU,
  'en-nz': enAU,
  'en-sg': enAU,
  'en-ie': enGB,
  'en-in': enIN,
  'en-us': enUS,
  'es-es': esES,
  'es-us': esUS,
  'fi-fi': fiFI,
  'fr-be': frFR,
  'fr-ca': frCA,
  'fr-ch': frFR,
  'fr-fr': frFR,
  'it-ch': itIT,
  'it-it': itIT,
  'nb-no': nbNO,
  'nl-be': nlNL,
  'nl-nl': nlNL,
  'pt-pt': ptPT,
  'sv-se': svSE,
};
