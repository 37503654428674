import React, { useState, useEffect } from 'react';
import { FlexBox, Spinner, Typography } from '@vp/swan';
import { useTranslations } from '@vp/om-translations';
import { useUserContext } from '@vp/ubik-context';
import { getShopperCart } from '../apis/vistacartApi';

export const CartApplication = ({
  tenant,
  shopperId,
}: {
  tenant: string;
  shopperId: string;
}) => {
  const [cartMessages] = useTranslations(['cartMessages']);
  const context = useUserContext();

  // just mocking the getCartLoader
  const [cartData, setCartData] = useState('');

  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function getToken() {
      const response = await getShopperCart(
        tenant,
        shopperId,
        false // shouldLoadOffline
      );
      //   const data = await response.json();
      //   setCartData(data.access_token);
      setCartData(response);
    }

    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!cartData) {
      getToken();
    }
  }, [cartData, shopperId, tenant]);

  return (
    <>
      <Typography mb={2} fontSize="x2large">
        {cartMessages.titleMessage}
      </Typography>
      <Typography>UBIK Context environment: {context.environment}</Typography>
      <Typography>UBIK Context tenant: {context.tenant}</Typography>
      <Typography>UBIK Context locale: {context.locale}</Typography>
      {!cartData ? (
        <FlexBox justifyContent="center">
          <Spinner
            size="super"
            accessibleText={cartMessages.loading}
            aria-busy="true"
            aria-live="polite"
            role="status"
            my={9}
          />
        </FlexBox>
      ) : (
        <>{JSON.stringify(cartData)}</>
      )}
    </>
  );
};
