import { useContext } from 'react';
// import {
//   determineEntryTypeFromOrigin,
//   PROXY_ACCESS_ENTRY,
// } from '@vp/common-dev-tooling';
import SiteContext from '../components/contexts/SiteContext';

const useSiteData = () => {
  const {
    country,
    featureControls,
    geoData = {},
    nav = {},
    optimizelyExperiments,
    paymentOptions = {},
    siteName,
    tenantEnvironment,
    uiData = {},
    auth,
    uiLocalization = {},
    vistacartTenantName: vistacartTenant,
    tracking = {},
  } = useContext(SiteContext);

  //   const entryType = determineEntryTypeFromOrigin();

  const { locale } = uiLocalization;
  const siteCountry = country || null;
  const allowAnonymousCartSessions = auth?.allowAnonymous || false;
  const allowAnonymousCheckoutSessions = auth?.allowAnonymous || false;
  const authSite = auth?.site || null;
  const noCouponEntry = uiData?.cart?.noCouponEntry || false;
  const pricingOptionsMerchantId =
    uiData?.pricingOptions?.merchantId || 'vistaprint';
  const enabledExperimentKeys =
    optimizelyExperiments?.enabledExperimentKeys || undefined;
  const shippingPricingInCartType =
    uiData?.cart?.shippingDisplayOptions?.shippingPricingInCartType || 'none';

  const addressAutocompleteData = {
    ...uiData?.addressAutocomplete,
    ...uiData?.checkout?.addressAutocomplete,
  };

  const addressValidationData = {
    ...uiData?.addressValidation,
    ...uiData?.checkout?.addressValidation,
  };

  const paymentEnvironment =
    paymentOptions?.paymentEnvironment || tenantEnvironment;

  const navDefaults = {
    orderHistory: `/oh/`,
    cart: `/c/`,
    orderDetails: `/od/`,
    checkout: `/co/`,
  };

  const navOverrides = {};

  //   if (entryType !== PROXY_ACCESS_ENTRY) {
  //     navOverrides = {
  //       orderHistory: `https://orderdetailsui.cdn.orders.vpsvc.com/${siteName}/${locale}/orderhistory`,
  //       cart: `/${siteName}/${locale}/cart`,
  //       orderDetails: `https://orderdetailsui.cdn.orders.vpsvc.com/${siteName}/${locale}/orderdetails`,
  //       checkout: `https://checkoutui.cdn.orders.vpsvc.com/${siteName}/${locale}/checkout`,
  //     };
  //   }

  return {
    allowAnonymousCartSessions,
    allowAnonymousCheckoutSessions,
    authSite,
    enabledExperimentKeys,
    externalAppNavigation: { ...navDefaults, ...nav, ...navOverrides },
    featureControls,
    geoData,
    locale,
    noCouponEntry,
    paymentEnvironment,
    pricingOptionsMerchantId,
    shippingPricingInCartType,
    siteCountry,
    siteName,
    uiLocalization,
    vistacartTenant,
    tracking,
    uiData,
    addressValidationData,
    addressAutocompleteData,
  };
};

export default useSiteData;
